// debugConfig.js
import { getAuthConfig } from '@/app/ams-v2/services/api';

let debugMode = localStorage.getItem('debugMode') === 'false' ? false : true;

export const setDebugMode = async (value) => {
  debugMode = value;
  localStorage.setItem('debugMode', value); // 存儲到 localStorage

  // 當 debugMode 更新時呼叫 getAuthConfig
  try {
    await getAuthConfig(false);
    // 添加 2 秒延遲後才重新整理
    setTimeout(() => {
      window.location.reload();
    }, 1000);
  } catch (error) {
    console.error('Failed to update auth config:', error);
  }

  // 發送自定義事件通知其他組件
  const event = new CustomEvent('debugModeChange', { detail: value });
  window.dispatchEvent(event);
};

export const getDebugMode = () => {
  const storedValue = localStorage.getItem('debugMode');
  if (storedValue === null) {
    return debugMode;
  }
  return storedValue === 'true';
};