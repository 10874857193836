import ISearchRepository from '@/domain/interfaces/ISearchRepository';
import { SearchApi } from '../api/SearchApi';

export class SearchRepository extends ISearchRepository {
  /**
   * @inheritdoc
   */
  async searchClients(chatroomId, searchText) {
    try {
      console.log('SearchRepository searchText:', searchText); // 調試日誌
      const response = await SearchApi.searchClients(chatroomId, searchText);
      return response;
    } catch (error) {
      console.error('SearchRepository.searchClients Error:', error);
      throw error;
    }
  }
} 