const env = {
    stag5: process.env.REACT_APP_STAG5_E_COMMERCE === 'true',
    panco: process.env.REACT_APP_PANCO_E_COMMERCE === 'true',
    e77: process.env.REACT_APP_77_E_COMMERCE === 'true',
    healtech: process.env.REACT_APP_E_OFFICIAL_SITE_MODULE === 'true',
    sc: process.env.REACT_APP_E_OFFICIAL_SITE_MODULE === 'true',
  };
  
  export const isStag5 = env.stag5;
  export const isPanco = env.panco;
  export const isE77 = env.e77;
  export const isHealtech = env.healtech;
  export const isSC = env.sc;

  export const currentEnv = () => {
    if (env.stag5) return 'stag5';
    if (env.panco) return 'panco';
    if (env.e77) return 'e77';
    if (env.healtech) return 'healtech';
    if (env.sc) return 'sc';
    return 'unknown';
  };
  