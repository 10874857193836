import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom';
import classNames from 'classnames';
import { ReactComponent as ExploreIcon } from './assets/explore_tab_icon.svg';
import { ReactComponent as ChatIcon } from './assets/chat_tab_icon.svg';
import { ReactComponent as ManagementIcon } from './assets/management_tab_icon.svg';
import { ReactComponent as DemandIcon } from './assets/ams_demand_icon.svg';
import { ReactComponent as ServiceIcon } from './assets/ams_service_icon.svg';
import { ReactComponent as ProductIcon } from './assets/ams_product_icon.svg';
import { ReactComponent as UserIcon } from './assets/ams_user_icon.svg';
import { ReactComponent as OrganizationIcon } from './assets/ams_organization_icon.svg';
import { ReactComponent as TicketIcon } from './assets/ams_ticket_icon.svg';
import { ReactComponent as VenueIcon } from './assets/ams_venue_icon.svg';
import { ReactComponent as CampaignIcon } from './assets/ams_campaign_icon.svg';

import Logo from '../logo/Logo';
import { FloatingPortal, useFloating, useHover, useInteractions, offset, FloatingArrow, arrow } from '@floating-ui/react';

const useTooltip = (label) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const arrowRef = React.useRef(null);

  const { refs, floatingStyles, context } = useFloating({
    open: isOpen,
    onOpenChange: setIsOpen,
    placement: 'right',
    middleware: [
      offset(10),
      arrow({ element: arrowRef })
    ],
  });

  const hover = useHover(context);
  const { getReferenceProps, getFloatingProps } = useInteractions([hover]);

  const TooltipContent = React.useMemo(() => {
    return isOpen ? (
      <FloatingPortal>
        <div
          ref={refs.setFloating}
          style={{
            ...floatingStyles,
            zIndex: 1000
          }}
          {...getFloatingProps()}
          className="bg-gray-800 text-white px-2 py-1 rounded text-sm"
        >
          {label}
          <FloatingArrow
            ref={arrowRef}
            context={context}
            className="fill-gray-800"
          />
        </div>
      </FloatingPortal>
    ) : null;
  }, [isOpen, refs.setFloating, floatingStyles, getFloatingProps, context, label, arrowRef]);

  return {
    refs,
    getReferenceProps,
    TooltipContent
  };
};

const NavBar = () => {
  const location = useLocation();
  const render_official_site = process.env.REACT_APP_E_OFFICIAL_SITE_MODULE === 'true';
  const render_form = process.env.REACT_APP_HEALTECH_AI_FORM === 'true';
  const isPanco = process.env.REACT_APP_PANCO_E_COMMERCE === 'true';
  const isStag = process.env.REACT_APP_STAG5_E_COMMERCE === 'true';
  const is77 = process.env.REACT_APP_77_E_COMMERCE === 'true';
  const isHealtech = process.env.REACT_APP_E_OFFICIAL_SITE_MODULE === 'true';
  const isSC = process.env.REACT_APP_E_OFFICIAL_SITE_MODULE === 'true';

  const exploreTooltip = useTooltip(isStag ? 'Explore' : '探索');
  const chatTooltip = useTooltip(isStag ? 'Chat' : '聊天');

  const managementTooltip = useTooltip('後台管理');
  const demandTooltip = useTooltip('需求管理');

  const serviceTooltip = useTooltip(isStag ? 'Service' : '服務管理');
  const productTooltip = useTooltip(isStag ? 'Product' : '商品管理');
  const userTooltip = useTooltip(isStag ? 'User' : '用戶管理');
  const organizationTooltip = useTooltip(isStag ? 'Store' : '店家管理');

  const ticketTooltip = useTooltip('票券管理');
  const venueTooltip = useTooltip('場館管理');
  const campaignTooltip = useTooltip('活動管理');

  useEffect(() => {
    if (!localStorage.getItem('debugMode')) {
      localStorage.setItem('debugMode', 'false');
    }
  }, []);

  useEffect(() => {

    const OFFICIAL_SITE = process.env.REACT_APP_E_OFFICIAL_SITE_MODULE
    const STAG5 = process.env.REACT_APP_STAG5_E_COMMERCE
    const PANCO = process.env.REACT_APP_PANCO_E_COMMERCE
    const E77 = process.env.REACT_APP_77_E_COMMERCE
    const HEALTECH = process.env.REACT_APP_HEALTECH_AI_FORM

    console.log('OFFICIAL_SITE', OFFICIAL_SITE);
    console.log('STAG5', STAG5);
    console.log('PANCO', PANCO);
    console.log('E77', E77);
    console.log('HEALTECH', HEALTECH);
    
    
  }, []);

  // Add helper function to check if path matches exactly the first segment
  const isActiveRoute = (path) => {
    const currentPath = location.pathname.split('/')[1]; // Get first segment after domain
    return currentPath === path.replace('/', '');
  };

  return (
    <div className="flex flex-col items-center justify-start h-screen min-w-24 w-24 pt-4 gap-4 bg-bgDark">
      <a href="/" className='w-[45px] h-[45px] flex justify-center items-center overflow-hidden' >
        <Logo type="NAVBAR" />
      </a>
      <a href="/explore"
        ref={exploreTooltip.refs.setReference}
        {...exploreTooltip.getReferenceProps()}
        className={classNames({
          'border-primary': isActiveRoute('/explore'),
          'flex w-11 h-11 items-center justify-center cursor-pointer border-[2px] border-borderDark hover:border-primaryMedium rounded-full': true,
        })}>
        <ExploreIcon />
        {exploreTooltip.TooltipContent}
      </a>

      <a href="/chat"
        ref={chatTooltip.refs.setReference}
        {...chatTooltip.getReferenceProps()}
        className={classNames({
          'border-primary': isActiveRoute('/chat'),
          'flex w-11 h-11 items-center justify-center cursor-pointer border-[2px] border-borderDark hover:border-primaryMedium rounded-full': true,
        })}>
        <ChatIcon />
        {chatTooltip.TooltipContent}
      </a>
      {render_form && (
        <div className='flex flex-col items-center justify-start gap-4'>
         
          <a href="/demand"
            ref={demandTooltip.refs.setReference}
            {...demandTooltip.getReferenceProps()}
            className={classNames({
              'border-primary': isActiveRoute('/demand'),
              'flex w-11 h-11 items-center justify-center cursor-pointer border-[2px] border-borderDark hover:border-primaryMedium rounded-full': true,
            })}>
            <DemandIcon />
            {demandTooltip.TooltipContent}
          </a>
        </div>
      )}
      {
        render_official_site && (
          <div className='flex flex-col items-center justify-start gap-4'>
          <a href="/management"
            ref={managementTooltip.refs.setReference}
            {...managementTooltip.getReferenceProps()}
            className={classNames({
              'border-primary': isActiveRoute('/management'),
              'flex w-11 h-11 items-center justify-center cursor-pointer border-[2px] border-borderDark hover:border-primaryMedium rounded-full': true,
            })}>
            <ManagementIcon />
            {managementTooltip.TooltipContent}
          </a>
        </div>
        )
      }
      {isPanco && (
        <div className='flex flex-col items-center justify-start gap-4'>
          <a href="/user"
            ref={userTooltip.refs.setReference}
            {...userTooltip.getReferenceProps()}
            className={classNames({
              'border-primary': isActiveRoute('/user'),
              'flex w-11 h-11 items-center justify-center cursor-pointer border-[2px] border-borderDark hover:border-primaryMedium rounded-full': true,
            })}>
            <UserIcon />
            {userTooltip.TooltipContent}
          </a>
          <a href="/organization"
            ref={organizationTooltip.refs.setReference}
            {...organizationTooltip.getReferenceProps()}
            className={classNames({
              'border-primary': isActiveRoute('/organization'),
              'flex w-11 h-11 items-center justify-center cursor-pointer border-[2px] border-borderDark hover:border-primaryMedium rounded-full': true,
            })}>
            <OrganizationIcon className='w-6 h-6' />
            {organizationTooltip.TooltipContent}
          </a>
          <a href="/service"
            ref={serviceTooltip.refs.setReference}
            {...serviceTooltip.getReferenceProps()}
            className={classNames({
              'border-primary': isActiveRoute('/service'),
              'flex w-11 h-11 items-center justify-center cursor-pointer border-[2px] border-borderDark hover:border-primaryMedium rounded-full': true,
            })}>
            <ServiceIcon />
            {serviceTooltip.TooltipContent}
          </a>
          <a href="/product"
            ref={productTooltip.refs.setReference}
            {...productTooltip.getReferenceProps()}
            className={classNames({
              'border-primary': isActiveRoute('/product'),
              'flex w-11 h-11 items-center justify-center cursor-pointer border-[2px] border-borderDark hover:border-primaryMedium rounded-full': true,
            })}>
            <ProductIcon />
            {productTooltip.TooltipContent}
          </a>
        </div>
      )}

      {isStag && (
        <div className='flex flex-col items-center justify-start gap-4'>
          <a href="/user"
            ref={userTooltip.refs.setReference}
            {...userTooltip.getReferenceProps()}
            className={classNames({
              'border-primary': isActiveRoute('/user'),
              'flex w-11 h-11 items-center justify-center cursor-pointer border-[2px] border-borderDark hover:border-primaryMedium rounded-full': true,
            })}>
            <UserIcon />
            {userTooltip.TooltipContent}
          </a>
          <a href="/organization"
            ref={organizationTooltip.refs.setReference}
            {...organizationTooltip.getReferenceProps()}
            className={classNames({
              'border-primary': isActiveRoute('/organization'),
              'flex w-11 h-11 items-center justify-center cursor-pointer border-[2px] border-borderDark hover:border-primaryMedium rounded-full': true,
            })}>
            <OrganizationIcon className='w-6 h-6' />
            {organizationTooltip.TooltipContent}
          </a>
          <a href="/service"
            ref={serviceTooltip.refs.setReference}
            {...serviceTooltip.getReferenceProps()}
            className={classNames({
              'border-primary': isActiveRoute('/service'),
              'flex w-11 h-11 items-center justify-center cursor-pointer border-[2px] border-borderDark hover:border-primaryMedium rounded-full': true,
            })}>
            <ServiceIcon />
            {serviceTooltip.TooltipContent}
          </a>
          <a href="/product"
            ref={productTooltip.refs.setReference}
            {...productTooltip.getReferenceProps()}
            className={classNames({
              'border-primary': isActiveRoute('/product'),
              'flex w-11 h-11 items-center justify-center cursor-pointer border-[2px] border-borderDark hover:border-primaryMedium rounded-full': true,
            })}>
            <ProductIcon />
            {productTooltip.TooltipContent}
          </a>
        </div>
      )}

      {is77 && (
        <div className='flex flex-col items-center justify-start gap-4'>
          <a href="/user"
            ref={userTooltip.refs.setReference}
            {...userTooltip.getReferenceProps()}
            className={classNames({
              'border-primary': isActiveRoute('/user'),
              'flex w-11 h-11 items-center justify-center cursor-pointer border-[2px] border-borderDark hover:border-primaryMedium rounded-full': true,
            })}>
            <UserIcon />
            {userTooltip.TooltipContent}
          </a>
          <a href="/organization"
            ref={organizationTooltip.refs.setReference}
            {...organizationTooltip.getReferenceProps()}
            className={classNames({
              'border-primary': isActiveRoute('/organization'),
              'flex w-11 h-11 items-center justify-center cursor-pointer border-[2px] border-borderDark hover:border-primaryMedium rounded-full': true,
            })}>
            <OrganizationIcon className='w-6 h-6' />
            {organizationTooltip.TooltipContent}
          </a>
          <a href="/ticket"
            ref={ticketTooltip.refs.setReference}
            {...ticketTooltip.getReferenceProps()}
            className={classNames({
              'border-primary': isActiveRoute('/ticket'),
              'flex w-11 h-11 items-center justify-center cursor-pointer border-[2px] border-borderDark hover:border-primaryMedium rounded-full': true,
            })}>
            <TicketIcon />
            {ticketTooltip.TooltipContent}
          </a>
          <a href="/venue"
            ref={venueTooltip.refs.setReference}
            {...venueTooltip.getReferenceProps()}
            className={classNames({
              'border-primary': isActiveRoute('/venue'),
              'flex w-11 h-11 items-center justify-center cursor-pointer border-[2px] border-borderDark hover:border-primaryMedium rounded-full': true,
            })}>
            <VenueIcon />
            {venueTooltip.TooltipContent}
          </a>
          <a href="/campaign"
            ref={campaignTooltip.refs.setReference}
            {...campaignTooltip.getReferenceProps()}
            className={classNames({
              'border-primary': isActiveRoute('/campaign'),
              'flex w-11 h-11 items-center justify-center cursor-pointer border-[2px] border-borderDark hover:border-primaryMedium rounded-full': true,
            })}>
            <CampaignIcon />
            {campaignTooltip.TooltipContent}
          </a>
        </div>
      )}

      <div
        className="text-xs text-transparent"
      >
        v-0124-05


        {/* {
          isDev && (
            <DevModeSelector />
          )
        } */}
      </div>

    </div>
  )
}

export default NavBar
