/**
 * 搜尋客戶的UseCase
 */
export class SearchClientsUseCase {
  constructor(searchRepository) {
    this.searchRepository = searchRepository;
  }

  /**
   * 執行搜尋客戶
   * @param {string} chatroomId - 聊天室ID
   * @param {string} searchText - 搜尋文字
   * @returns {Promise<Array<string>>} - 返回符合條件的客戶external_id列表
   */
  async execute(chatroomId, searchText) {
    try {
      const result = await this.searchRepository.searchClients(chatroomId, searchText);
      return result;
    } catch (error) {
      console.error('SearchClientsUseCase Error:', error);
      throw error;
    }
  }
} 