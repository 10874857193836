import React from "react";
import LineSetting from "../chatSettings/LineSetting";
import AgodaSetting from "../chatSettings/AgodaSetting";

const BoundSetting = ({ room, token, clientId, tabs, client, updateClientData }) => {
  return (
    <>
      {tabs === 0 ? (
        <div className="bg-bgLight h-full min-w-72 max-w-72">
          <AgodaSetting
            client={client}
          />
        </div>
      ) : tabs === 1 ? (
        <div className="bg-bgLight h-full min-w-72 max-w-72 overflow-y-auto">
          <LineSetting 
            room={room} 
            token={token} 
            clientId={clientId} 
            client={client}
            updateClientData={updateClientData}
          />
        </div>
      ) : 
        ''
      }
    </>
  );
};

export default BoundSetting;