import { request } from '@/utils';

export const LineChannelApi = {
  /**
   * Add a note to a line channel client
   * @param {string} chatroomId - The chatroom ID
   * @param {string} clientId - The client ID
   * @param {string} noteName - The note name
   * @param {string} noteContent - The note content
   * @returns {Promise<Object>} The response from the API
   * @throws {Error} If the API call fails
   */
  addNote: async (chatroomId, clientId, noteName, noteContent) => {
    try {
      const response = await request.post(
        `/private/property/chatroom/line_channel/${chatroomId}/note/${clientId}`,
        null,
        {
          params: {
            note_name: noteName,
            note_content: noteContent
          }
        }
      );
      
      return response.data;
    } catch (error) {
      console.error('API Error in addNote:', error);
      if (error.response) {
        // 處理伺服器回應的錯誤
        const status = error.response.status;
        const detail = error.response.data?.detail;
        
        if (status === 404) {
          throw new Error('找不到指定的聊天室或客戶');
        } else if (status === 400) {
          throw new Error(detail || '無效的請求參數');
        } else if (status === 500) {
          throw new Error('伺服器錯誤');
        }
      }
      // 如果是網路錯誤或其他錯誤
      throw new Error('網路連線異常，請稍後再試');
    }
  },
  fetchLineClients: async (chatroomId) => {
    const response = await request.get(`/private/property/chatroom/line_channel/${chatroomId}/clients/v2?channel_type=line`);
    console.log('response', response);
    return response;
  },
  /**
   * Delete a note from a line channel client
   * @param {string} chatroomId - The chatroom ID
   * @param {string} clientId - The client ID
   * @param {string} noteId - The note ID
   * @returns {Promise<Object>} The response from the API
   * @throws {Error} If the API call fails
   */
  deleteNote: async (chatroomId, clientId, noteId) => {
    try {
      const response = await request.delete(
        `/private/property/chatroom/line_channel/${chatroomId}/note/${clientId}`,
        {
          params: {
            note_id: noteId
          }
        }
      );
      
      return response.data;
    } catch (error) {
      console.error('API Error in deleteNote:', error);
      if (error.response) {
        // 處理伺服器回應的錯誤
        const status = error.response.status;
        const detail = error.response.data?.detail;
        
        if (status === 404) {
          throw new Error('找不到指定的備註');
        } else if (status === 400) {
          throw new Error(detail || '無效的請求參數');
        } else if (status === 500) {
          throw new Error('伺服器錯誤');
        }
      }
      // 如果是網路錯誤或其他錯誤
      throw new Error('網路連線異常，請稍後再試');
    }
  },
  /**
   * Add a tag to a line channel client
   * @param {string} chatroomId - The chatroom ID
   * @param {string} clientId - The client ID
   * @param {string} tagName - The tag name
   * @returns {Promise<Object>} The response from the API
   * @throws {Error} If the API call fails
   */
  addTag: async (chatroomId, clientId, tagName) => {
    try {
      const response = await request.post(
        `/private/property/chatroom/line_channel/${chatroomId}/tag/${clientId}`,
        null,
        {
          params: {
            name: tagName
          }
        }
      );
      
      return response.data;
    } catch (error) {
      console.error('API Error in addTag:', error);
      if (error.response) {
        const status = error.response.status;
        const detail = error.response.data?.detail;
        
        if (status === 404) {
          throw new Error('找不到指定的聊天室或客戶');
        } else if (status === 400) {
          throw new Error(detail || '無效的請求參數');
        } else if (status === 500) {
          throw new Error('伺服器錯誤');
        }
      }
      throw new Error('網路連線異常，請稍後再試');
    }
  },
  /**
   * Delete a tag from a line channel client
   * @param {string} chatroomId - The chatroom ID
   * @param {string} clientId - The client ID
   * @param {string} tagId - The tag ID
   * @returns {Promise<Object>} The response from the API
   * @throws {Error} If the API call fails
   */
  deleteTag: async (chatroomId, clientId, tagId) => {
    try {
      const response = await request.delete(
        `/private/property/chatroom/line_channel/${chatroomId}/tag/${clientId}`,
        {
          params: {
            tag_id: tagId
          }
        }
      );
      
      return response.data;
    } catch (error) {
      console.error('API Error in deleteTag:', error);
      if (error.response) {
        const status = error.response.status;
        const detail = error.response.data?.detail;
        
        if (status === 404) {
          throw new Error('找不到指定的標籤');
        } else if (status === 400) {
          throw new Error(detail || '無效的請求參數');
        } else if (status === 500) {
          throw new Error('伺服器錯誤');
        }
      }
      throw new Error('網路連線異常，請稍後再試');
    }
  },
  fetchTagGroup: async (chatroomId) => {
    try {
      const response = await request.get(`/private/property/chatroom/line_channel/settings/${chatroomId}/tag_group`);
      return response;
    } catch (error) {
      console.error('API Error in fetchTagGroup:', error);
      throw error;
    }
  }
}; 
