// MySearchWithSelect.js
import React, { useState, useRef, useEffect } from 'react';
import { Input, Select } from 'antd';
import { SearchOutlined } from '@ant-design/icons';

function SearchUi({
  defaultSearchRange = '全部',
  onSearch,
  disabled,
  ...props
}) {
  const [searchRange, setSearchRange] = useState(defaultSearchRange);
  const [searchText, setSearchText] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);
  const containerRef = useRef(null);

  // Handle search logic
  const handleSearch = (text, range) => {
    setSearchText(text);
    if (onSearch) {
      onSearch(text, range);
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target) &&
          containerRef.current && !containerRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const handleSelectChange = (value) => {
    setSearchRange(value);
    handleSearch(searchText, value);
  };

  const handleInputChange = (e) => {
    const value = e.target.value;
    handleSearch(value, searchRange);
    if (props.onChange) {
      props.onChange(e);
    }
  };

  return (
    <div className="relative" ref={containerRef}>
      {/* Search Input */}
      <div className="flex items-center w-full bg-[#F5F5F5] rounded-lg border border-gray-200">
        <Input
          {...props}
          className="flex-grow !border-none !outline-none bg-[#F5F5F5]"
          style={{ boxShadow: 'none' }}
          prefix={<SearchOutlined className="text-gray-400" />}
          onChange={handleInputChange}
          value={searchText}
          allowClear
          disabled={disabled}
        />
        <Select
          value={searchRange}
          onChange={handleSelectChange}
          className="!border-none min-w-[80px] bg-[#F5F5F5]"
          popupClassName="hidden"
          bordered={false}
          suffixIcon={<span className="text-gray-400">▼</span>}
          onClick={() => disabled ? null : setIsOpen(!isOpen)}
          open={false}
          disabled={disabled}
        />
      </div>

      {/* Dropdown Menu */}
      {isOpen && (
        <div 
          ref={dropdownRef}
          className="absolute top-full left-0 right-0 mt-1 flex flex-col w-full bg-white rounded-lg shadow-lg border border-gray-200 p-4 z-50"
        >
          <div className="text-gray-400 text-sm mb-2">依照範圍搜尋</div>
          <div className="flex flex-col gap-2">
            {[
              { value: '全部', label: '全部' },
              { value: '名稱', label: '名稱' },
              { value: '別名', label: '別名' },
              { value: '訊息', label: '訊息' },
              { value: '備註', label: '備註' },
            ].map((option) => (
              <div
                key={option.value}
                className={`flex items-center px-3 py-2 rounded-lg cursor-pointer hover:bg-gray-50 gap-2  ${
                  searchRange === option.value ? 'text-gray-900 bg-gray-50' : 'text-gray-400'
                }`}
                onClick={() => {
                  handleSelectChange(option.value);
                  setIsOpen(false);
                }}
              >
                <span className="text-black">{option.label}</span>
                <span className="text-gray-400">搜尋</span>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
}

export default SearchUi;

