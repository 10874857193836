import { request, callApi } from '@/utils';
import { getDebugMode, setDebugMode } from '@/app/ams-v2/utils/debugConfig';  
import { message } from 'antd';

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

// ===== 認證相關 API =====
const AUTH_STORAGE_KEY = 'auth_config';
const AUTH_EXPIRY_TIME = 0.5 * 60 * 1000; // 5分鐘，單位為毫秒

const getStoredAuthConfig = () => {
  const storedData = localStorage.getItem(AUTH_STORAGE_KEY);
  if (!storedData) return null;
  
  try {
    const { accessToken, serverUrl, timestamp } = JSON.parse(storedData);
    const now = Date.now();
    
    // 檢查是否超過5分鐘
    if (now - timestamp > AUTH_EXPIRY_TIME) {
      localStorage.removeItem(AUTH_STORAGE_KEY);
      return null;
    }
    
    return { accessToken, serverUrl };
  } catch (error) {
    console.error('解析儲存的認證配置失敗:', error);
    localStorage.removeItem(AUTH_STORAGE_KEY);
    return null;
  }
};

const storeAuthConfig = (accessToken, serverUrl) => {
  const authData = {
    accessToken,
    serverUrl,
    timestamp: Date.now()
  };
  localStorage.setItem(AUTH_STORAGE_KEY, JSON.stringify(authData));
};

export const getAuthConfig = async (checkTime = true) => {
  try {
    if (checkTime) {
      // 先檢查 localStorage
    const storedConfig = getStoredAuthConfig();
    if (storedConfig) {
        return storedConfig;
      }
    }

    // 如果沒有有效的儲存配置，則重新獲取
    const debug = getDebugMode();
    const [accessToken, serverUrl] = await Promise.all([
      getAccessToken(debug),
      getServerUrl(debug)
    ]);

    // 儲存新的配置到 localStorage
    storeAuthConfig(accessToken, serverUrl);

    return {
      accessToken,
      serverUrl
    };
  } catch (error) {
    console.error('獲取認證配置失敗:', error);
    // // throw new Error('Failed to fetch authentication configuration');
  }
};

export const getOfficialAuthConfig = async () => {
  try {
    const [accessToken, serverUrl] = await Promise.all([
      getOfficialAccessToken(),
      getOfficialServerUrl()
    ]);

    return {
      accessToken,
      serverUrl
    };
  } catch (error) {
    console.error('獲取認證配置失敗:', error);
    // // throw new Error('Failed to fetch authentication configuration');
  }
};

export const getServerUrl = async (debug) => {
  try {
    const response = await request.get(`${SERVER_URL}/private/property/ecommerce/profile?debug=${debug}`);
    return response.body.server_url;
    
  } catch (error) {
    console.log('error', error);
    
    if (error && error.status === 404 && debug === true) {
      console.warn('404 錯誤，將 debugMode 切換為 false 並重新嘗試...');
      message.warning('開發模式尚未開啟，請聯絡客服');
      setDebugMode(false);
      return await getServerUrl(false); // 重新調用函數，使用 debug = false
    }
    console.error('獲取伺服器 URL 失敗:', error);
    // // throw new Error('Unable to fetch server URL');
  }
};

export const getAccessToken = async (debug) => {
  try {
    const response = await request.get(
      `${SERVER_URL}/private/property/ecommerce/jwt?debug=${debug}`
    );

    return response.access_token;
  } catch (error) {
    if (error && error.status === 404 && debug === true) {
      console.warn('404 錯誤，將 debugMode 模式關閉 並重新嘗試...');
      setDebugMode(false);
      return await getAccessToken(false); // 重新調用函數，使用 debug = false
    }
    console.error('獲取存取令牌失敗:', error);
    // // throw new Error('Unable to fetch access token');
  }
};

export const getOfficialAccessToken = async () => {
  try {
    const response = await request.get(`${SERVER_URL}/private/property/official_site/jwt`);
    console.log('response', response.access_token);
    return response.access_token;
  } catch (error) {
    console.error('獲取存取令牌失敗:', error);
    // // throw new Error('Unable to fetch access token');
  }
};

export const getOfficialServerUrl = async () => {
  try {
    const response = await request.get(`${SERVER_URL}/private/property/official_site/profile`);
    console.log('response', response.body.server_url);
    
    return response.body.server_url;
  } catch (error) {
    console.error('獲取伺服器 URL 失敗:', error);
    // // throw new Error('Unable to fetch server URL');
  }
};

// ===== 使用者相關 API =====
export const fetchUsersAPI = async () => {
  try {
    const response = await callApi.get('/system/user/all?skip=0&limit=100');
    return response.data.users;
  } catch (error) {
    console.error('獲取用戶數據失敗:', error);
    // // throw new Error('Unable to fetch users');
  }
};

export const fetchUserServiceOrdersAPI = async (userId) => {
  try {
    const response = await callApi.get(`/system/orders/list_by_user/${userId}?product_type=service_product_ii`);
    return response.data;
  } catch (error) {
    console.error('獲取用戶服務訂單失敗:', error);
    // // throw new Error('Unable to fetch service orders');
  }
};

export const fetchUserProductOrdersAPI = async (userId) => {
  try {
    const response = await callApi.get(`/system/orders/list_by_user/${userId}?product_type=normal_product_dp`);
    return response.data;
  } catch (error) {
    console.error('獲取用戶商品訂單失敗:', error);
    // // throw new Error('Unable to fetch product orders');
  }
};

export const fetchUserTicketOrdersAPI = async (userId) => {
  try {
    const response = await callApi.get(`/system/orders/list_by_user/${userId}?product_type=free_ticket`);
    return response.data;
  } catch (error) {
    console.error('獲取用戶票券訂單失敗:', error);
    // // throw new Error('Unable to fetch ticket orders');
  }
};

export const fetchUserCompaignsAPI = async (userId) => {
  try {
    const response = await callApi.get(`/system/campaign/${userId}`);
    return response.data;
  } catch (error) {
    console.error('獲取用戶活動列表失敗:', error);
  }
};

export const fetchIdentityAPI = async (identityId) => {
  try {
    const response = await callApi.get(`/public/identity/${identityId}`);
    return response;
  } catch (error) {
    console.error('獲取身份證列表失敗:', error);
  }
};

export const fetchIdentityListAPI = async (orgId) => {
  if (orgId) {
    try {
      const response = await callApi.get(`/public/identity/all/${orgId}`);
      
      if (response.data && Array.isArray(response.data)) {
        return response.data;
      }
      return [];
    } catch (error) {
      console.error('獲取美容師列表失敗:', error);
      // // throw error;
    }
  }
  return [];
};

export const fetchBeauticiansAPI = async (orgId) => {
  if (orgId) {
    try {
      const response = await callApi.get(`/private/identity/applications/org/${orgId}`);
      
      if (response.data && Array.isArray(response.data)) {
        return response.data;
      }
      return [];
    } catch (error) {
      console.error('獲取美容師列表失敗:', error);
      // // throw error;
    }
  }
  return [];
};

export const checkBeauticianAPI = async (identityId, applicationId) => {
  try {
    const response = await callApi.post(`/private/identity/check/${identityId}?application_id=${applicationId}&approved=true`);
    return response;
  } catch (error) {
    console.error('審核美容師失敗:', error);
    // // throw error;
  }
};

// ===== 店家相關 API =====
export const fetchOrganizationsAPI = async () => {
  try {
    const response = await callApi.get('/public/organization/');
    return response.data;
  } catch (error) {
    console.error('獲取店家列表失敗:', error);
    // // throw error;
  }
};

export const createOrganizationAPI = async (organizationData) => {
  try {
    const response = await callApi.post('/private/organization/', organizationData);
    return response;
  } catch (error) {
    console.error('創建店家失敗:', error);
    // // throw error;
  }
};

export const verifyOrganizationAPI = async (organizationId) => {
  try {
    const response = await callApi.post(`/system/organization/verify/${organizationId}`);
    return response;
  } catch (error) {
    console.error('驗證店家失敗:', error);
    // throw error;
  }
}

export const fetchOrganizationServiceOrdersAPI = async (productType, orgId) => {
  try {
    const response = await callApi.get(`/system/orders/list_by_org/${orgId}?product_type=${productType}`);
    return response.data;
  } catch (error) {
    console.error('獲取服務訂單失敗:', error);
    // // throw new Error('Unable to fetch service orders');
  }
};

export const fetchOrganizationProductOrdersAPI = async (productType, orgId) => {
  try {
    const response = await callApi.get(`/system/orders/list_by_org/${orgId}?product_type=${productType}`);
    return response.data;
  } catch (error) {
    console.error('獲取商品訂單失敗:', error);
    // // throw new Error('Unable to fetch product orders');
  }
};

export const updateOrganizationImagesAPI = async (orgId, { imagesToAdd }) => {
  try {
    const formData = new FormData();
    
    // 添加新圖片
    if (imagesToAdd && imagesToAdd.length > 0) {
      imagesToAdd.forEach(file => {
        formData.append('files', file); // 將參數名改為 'files'
      });
    }

    const url = `/private/organization/pictures/${orgId}`;

    const response = await callApi.post(url, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    
    return response;
  } catch (error) {
    console.error('更新店家圖片失敗:', error);
    throw error;
  }
};

// ===== 服務相關 API =====
export const fetchServicesAPI = async (productType, orgId) => {
  try {
    const url = orgId 
      ? `/public/product/?product_type=${productType}&org_id=${orgId}&limit=66666`
      : `/public/product/?product_type=${productType}&limit=66666`;
      
    const response = await callApi.get(url);
    return response.data;
  } catch (error) {
    console.error('獲取店家列表失敗:', error);
    // // throw error;
  }
};

export const fetchServiceOrdersAPI = async (productId) => {
  try {
    const response = await callApi.get(`/system/orders/list_by_product/${productId}`);
    return response.data;
  } catch (error) {
    console.error('獲取服務訂單失敗:', error);
    // // throw new Error('Unable to fetch service orders');
  }
};

export const updateServiceAPI = async (serviceId, updateData) => {
  try {
    const { name, description, notification } = updateData;
    const updates = [];
    
    // Update name
    if (name) {
      updates.push(
        callApi.patch(
          `/private/sp/${serviceId}?fields=name&value=${encodeURIComponent(name)}`
        )
      );
    }

    // Update description
    if (description) {
      updates.push(
        callApi.patch(
          `/private/sp/${serviceId}?fields=description&value=${encodeURIComponent(description)}`
        )
      );
    }

    // Update notification
    if (notification) {
      updates.push(
        callApi.patch(
          `/private/sp/${serviceId}?fields=notification&value=${encodeURIComponent(notification)}`
        )
      );
    }

    // Execute all updates concurrently
    await Promise.all(updates);
    return true;
    
  } catch (error) {
    console.error('更新服務數據失敗:', error);
    // // throw new Error('Unable to update service');
  }
};

export const createServiceAPI = async (formattedData, organizationId) => {
  try {
    const response = await callApi.post(`/private/product/${organizationId}`, formattedData);
    return response;
  } catch (error) {
    console.error('儲存服務數據失敗:', error);
    // // throw new Error('Unable to save service');
  }
};

export const createProviderAPI = async (serviceId, identityId, providerId) => {
  try {
    const body = {
      identity_id: identityId,
      user_id: providerId
    }
    const response = await callApi.post(`/private/product/${serviceId}/provider`,body);
    return response;
  } catch (error) {
    console.error('新增美容師失敗:', error);
    // // throw error;
  }
};

export const fetchProductOrdersAPI = async (productId) => {
  try {
    const response = await callApi.get(`/system/orders/list_by_product/${productId}`);
    return response.data;
  } catch (error) {
    console.error('獲取產品訂單失敗:', error);
    // throw new Error('Unable to fetch product orders');
  }
};

export const fetchOfficialProductsAPI = async () => {
  try {
    const response = await callApi.get('/group/6fbba4f2-d965-452a-8ec4-75e780317b25');
    console.log('response:', response);
    return response;
  } catch (error) {
    console.error('獲取需求單數據失敗:', error);
    // throw new Error('Unable to fetch demand');
  }
};

export const updateProductImagesAPI = async (productId, { imagesToAdd, imagesToRemove = [] }) => {
  try {
    const formData = new FormData();
    
    // 添加新圖片
    if (imagesToAdd && imagesToAdd.length > 0) {
      imagesToAdd.forEach(file => {
        formData.append('images_to_add', file);
      });
    }

    // 構建 URL，包含要刪除的圖片 ID
    let url = `/private/product/${productId}/images`;
    if (imagesToRemove.length > 0) {
      const removeQuery = imagesToRemove.map(id => `images_to_remove=${id}`).join('&');
      url += `?${removeQuery}`;
    }

    const response = await callApi.patch(url, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    
    return response;
  } catch (error) {
    console.error('更新產品圖片失敗:', error);
    // throw error;
  }
};

// ===== 票券相關 API =====
export const fetchTicketsOrdersAPI = async (productId, specId) => {
  try {
    if (!productId || !specId) {
      return [];
    }
    
    const url = `/system/ticket/config/${productId}?product_spec_id=${specId}`;
    const response = await callApi.get(url);
    // 確保返回的是數組
    return response.data;
  } catch (error) {
    console.error('獲取票券數據失敗:', error);
    return []; // 發生錯誤時返回空數組
  }
};

// 發送票券給使用者
export const sendTicketAPI = async (productId, productSpecId, userIds) => {
  try {
    const queryParams = new URLSearchParams();
    queryParams.append('product_spec_id', productSpecId);
    userIds.forEach(id => queryParams.append('user_ids', id));
    
    const response = await callApi.post(
      `/private/ticket/assign/${productId}?${queryParams.toString()}`
    );
    return response;
  } catch (error) {
    console.error('發送票券失敗:', error);
  }
};

// ===== 場地相關 API =====
export const getEarliestOrgId = async () => {
  try {
    // 1. 獲取店家列表
    const response = await callApi.get('/public/organization/');
    
    // 2. 確保有返回數據且為數組
    if (!response.data || !Array.isArray(response.data) || response.data.length === 0) {
      return null;
    }

    // 3. 根據 created_at 排序並獲取最早創建的店家
    const earliestOrg = response.data.reduce((earliest, current) => {
      if (!earliest || new Date(current.created_at) < new Date(earliest.created_at)) {
        return current;
      }
      return earliest;
    }, null);

    // 4. 返回店家 ID
    return earliestOrg ? earliestOrg.id : null;
  } catch (error) {
    console.error('獲取最早店家ID失敗:', error);
    // throw new Error('Unable to fetch earliest organization ID');
  }
};

// 修改後的 fetchVenueCategoryIdAPI
export const fetchVenueCategoryIdAPI = async () => {
  try {
    // 1. 獲取最早的店家 ID
    const orgId = await getEarliestOrgId();
    if (!orgId) return [];

    // 2. 獲取場地數據
    const venueResponse = await callApi.get(`/public/erp/category/${orgId}/category/detail`);

    // 3. 如果場地數據數組內沒有 name=venue 的數據，則創建一個
    if (!venueResponse.data.find(item => item.name === 'venue')) {
      const response = await callApi.post(`/private/erp/category/${orgId}/category?category_name=venue`);
      return {
        venueCategoryId: response.data.find(item => item.name === 'venue').id,
        orgId: orgId
      };
    }

    return {
      venueCategoryId: venueResponse.data.find(item => item.name === 'venue').id,
      orgId: orgId
    };

  } catch (error) {
    console.error('獲取場地數據失敗:', error);
    // throw new Error('Unable to fetch venue');
  }
};

// create venue resource
export const createVenueResourceAPI = async (orgId, formattedData) => {
  try {
    const response = await callApi.post(`/private/erp/src/${orgId}`, formattedData);
    return response;
  } catch (error) {
    console.error('創建場地失敗:', error);
    // throw new Error('Unable to create venue');
  }
};


// fetch venue resources
export const fetchVenueResourcesAPI = async () => {
  try {
    const { orgId, venueCategoryId } = await fetchVenueCategoryIdAPI();
    const response = await callApi.get(`/public/erp/category/${orgId}/category/detail/reverse/byID?category_id=${venueCategoryId}`);
    
    // 過濾只返回 parent 為 null 的場館資料
    const venueResources = response.data.resources.filter(resource => resource.parent === null);
    return venueResources;
  } catch (error) {
    console.error('獲取場地數據失敗:', error);
    // throw new Error('Unable to fetch venue');
  }
};

// fetch halls by resource id
export const fetchVenueByResourceIdAPI = async (resourceId) => {
  try {
    const response = await callApi.get(`/public/erp/src/${resourceId}/detail`);
    
    // 過濾只返回 parent 為 null 的場館資料
    const venueResources = response.data.children
    return venueResources;
  } catch (error) {
    console.error('獲取場地數據失敗:', error);
    // throw new Error('Unable to fetch venue');
  }
};

export const fetchVenueBodyByResourceIdAPI = async (resourceId) => {
  try {
    const response = await callApi.get(`/public/erp/src/${resourceId}/detail`);
    
    // 過濾只返回 parent 為 null 的場館資料
    const venueResources = response.data
    return venueResources;
  } catch (error) {
    console.error('獲取場地數據失敗:', error);
    // throw new Error('Unable to fetch venue');
  }
};

// fetch halls by resource id
export const fetchHallsByResourceIdAPI = async (resourceId) => {
  try {
    const response = await callApi.get(`/public/erp/src/${resourceId}/detail`);
    
    // 過濾只返回 parent 為 null 的場館資料
    const venueResources = response.data.children
    return venueResources;
  } catch (error) {
    console.error('獲取場地數據失敗:', error);
    // throw new Error('Unable to fetch venue');
  }
};


// create hall resource
export const createHallResourceAPI = async (orgId, formattedData) => {
  try {
    const response = await callApi.post(`/private/erp/src/${orgId}`, formattedData);
    return response;
  } catch (error) {
    console.error('創建空間失敗:', error);
    // throw new Error('Unable to create hall');
  }
};

// fetch halls
export const fetchHallsAPI = async (resourceId) => {
  try {
    const response = await callApi.get(`/public/erp/src/${resourceId}/detail`);
    
    // 過濾只返回 parent 為 null 的場館資料
    console.log('response:', response.data.children);
    
    const venueResources = response.data.children;
    console.log('venueResources:', venueResources);
    return venueResources;
  } catch (error) {
    console.error('獲取場地數據失敗:', error);
    // throw new Error('Unable to fetch venue');
  }
};


// ===== 活動相關 API =====
export const fetchCampaignAPI = async () => {
  try {
    const response = await callApi.get('/system/campaign/');
    if (response.data) {
      return response.data;
    }
    return [];
  } catch (error) {
    console.error('獲取活動數據失敗:', error);
    // throw new Error('Unable to fetch campaign');
  }
};

// create campaign
export const createCampaignAPI = async (formattedData) => {
  try {
    const orgId = await getEarliestOrgId();
    const response = await callApi.post(`/private/campaign/${orgId}`, formattedData);
    return response;
  } catch (error) {
    console.error('創建活動失敗:', error);
    // throw new Error('Unable to create campaign');
  }
};

// note bulk
export const createNoteBulkAPI = async (campaignId, formattedData) => {
  try {
    const response = await callApi.post(`/public/campaign/note/${campaignId}/public/bulk`, formattedData);
    return response;
  } catch (error) {
    console.error('創建備註失敗:', error);
    // throw new Error('Unable to create note');
  }
};

// note
export const createNoteAPI = async (campaignId, formattedData) => {
  try {
    const response = await callApi.post(`/private/campaign/note/${campaignId}`, formattedData);
    return response;
  } catch (error) {
    console.error('創建備註失敗:', error);
    // throw new Error('Unable to create note');
  }
};

// create user admin
export const createUserAdminAPI = async (campaignId, userId) => {
  try {
    const response = await callApi.post(`/private/campaign/admin/${campaignId}?user_id=${userId}`);
    return response;
  } catch (error) {
    console.error('創建用戶失敗:', error);
    // throw new Error('Unable to create user');
  }
};

// ===== 規格與折扣相關 API =====
export const createSpecAPI = async (serviceId, specData) => {
  try {
    const response = await callApi.post(`/private/product/${serviceId}/spec`, specData);
    return response.data;
  } catch (error) {
    console.error('新增規格失敗:', error);
    // throw error;
  }
};

export const createTicketAPI = async (productId, productSpecId) => {
  try {
    const response = await callApi.post(`/private/ticket/create/${productId}?product_spec_id=${productSpecId}`);
    return response.data;
  } catch (error) {
    console.error('新增規格失敗:', error);
    // throw error;
  }
};

export const createDiscountAPI = async (serviceId, discountData) => {
  try {
    console.log('創建折扣前的數據檢查:', {
      serviceId,
      discountData,
      hasValidFrom: !!discountData.valid_from,
      hasValidUntil: !!discountData.valid_until,
      validFromType: typeof discountData.valid_from,
      validUntilType: typeof discountData.valid_until
    });

    // Ensure date format is correct
    const formattedData = {
      ...discountData,
      valid_from: discountData.valid_from || null,
      valid_until: discountData.valid_until || null
    };

    const response = await callApi.post(`/private/product/${serviceId}/discount`, formattedData);
    return response;
  } catch (error) {
    console.error('折扣創建錯誤:', {
      error,
      requestData: discountData,
      response: error.response?.data
    });
    // throw error;
  }
};

// ===== 分類相關 API =====
export const fetchCategoriesAPI = async (type) => {
  try {
    const response = await callApi.get(`/public/category/?product_type=${type}`);
    
    if (response.data) {
      const categoryList = response.data.map(category => ({
        id: category.id,
        name: category.name,
        product_type: category.product_type,
        category_id: category.id
      }));
      
      return categoryList;
    }
    return [];
  } catch (error) {
    console.error('獲取類別表失敗:', error);
    // throw error;
  }
};
