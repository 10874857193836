/**
 * @interface ISearchRepository
 */
export default class ISearchRepository {
  /**
   * 搜尋聊天室中的客戶
   * @param {string} chatroomId - 聊天室ID
   * @param {string} searchText - 搜尋文字
   * @returns {Promise<Array<string>>} - 返回符合條件的客戶external_id列表
   */
  async searchClients(chatroomId, searchText) {
    throw new Error('Method not implemented');
  }
} 