import { CacheApi } from '../api/CacheApi';

export class CacheRepository {
  static async setLastSeenMessageCache(chatroomId, externalChannelId, externalId, lastMessageId) {
    try {
      const key = `last_seen_message_id:${chatroomId}:${externalChannelId}:${externalId}`;
      return await CacheApi.setCacheStr(key, lastMessageId.toString());
    } catch (error) {
      console.error('Error in CacheRepository:', error);
      throw error;
    }
  }
} 