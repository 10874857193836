/* eslint-disable react-hooks/exhaustive-deps */

import React, { createContext, useContext, useState, useEffect } from 'react';
import axios from 'axios';

const SERVER_URL = process.env.REACT_APP_SERVER_URL;
const UPDATE_DURATION = 1 * 15 * 60 * 1000; // 15 minutes in milliseconds

const AuthContext = createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export const AuthProvider = ({ children }) => {
  const [username, setUsername] = useState(null);
  const [userId, setUserId] = useState(null);
  const [userRoleId, setUserRoleId] = useState(null);
  const [token, setToken] = useState(null);
  const [loading, setLoading] = useState(true);
  const [debugMode, setDebugMode] = useState(false);

  const handleDebugModeChange = (value) => {
    setDebugMode(value);
    localStorage.setItem('debugMode', value);
  };

  const login = (username, userId, userRoleId, token) => {
    setUsername(username);
    setUserId(userId);
    setUserRoleId(userRoleId);
    setToken(token);

    // Set cookies with 30 minutes expiration
    // Cookies.set('username', username, { expires, secure: true, sameSite: 'None', httpOnly: true });
    // Cookies.set('userId', userId, { expires, secure: true, sameSite: 'None', httpOnly: true });
    // Cookies.set('userRoleId', userRoleId, { expires, secure: true, sameSite: 'None', httpOnly: true });
    // Cookies.set('token', token, { expires, secure: true, sameSite: 'None', httpOnly: true });
    // TODO: replace local storage with cookies, this doesn't support expires
    localStorage.setItem('username', username);
    localStorage.setItem('userId', userId);
    localStorage.setItem('userRoleId', userRoleId);
    localStorage.setItem('token', token);
  };

  const logout = () => {
    setUsername(null);
    setUserId(null);
    setUserRoleId(null);
    setToken(null);

    // Cookies.remove('username');
    // Cookies.remove('userId');
    // Cookies.remove('userRoleId');
    // Cookies.remove('token');
    // TODO: replace local storage with cookies, this doesn't support expires
    localStorage.removeItem('username');
    localStorage.removeItem('userId');
    localStorage.removeItem('userRoleId');
    localStorage.removeItem('token');
  };

  const refreshToken = async () => {
    // console.log('Refreshing token...');
    try {
      const response = await axios.post(SERVER_URL + '/private/auth/refresh', {}, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      if (response.status === 200) {
        setToken(response.data.access_token);

        // Cookies.set('token', response.data.access_token, { expires, secure: true, sameSite: 'None', httpOnly: true });
        // TODO: replace local storage with cookies, this doesn't support expires
        localStorage.setItem('token', response.data.access_token);

        return
      } else {
        // console.error('Failed to refresh token');
        alert('需要重新登入');
        logout(); // Log out user if token refresh fails
      }
    } catch (error) {
      // console.error('Error refreshing token:', error);
      alert('需要重新登入');
      logout(); // Log out user if token refresh fails
    }
  };

  // Use useEffect to set up the interval
  useEffect(() => {
    const tokenInterval = setInterval(() => {
      if (token) {
        refreshToken();
      }
    }, UPDATE_DURATION); // Refresh token every 30 minutes

    // Clean up interval on component unmount
    return () => clearInterval(tokenInterval);
  }, [token]); // Depend on token so interval resets if token changes

  // Initial token read from cookies
  useEffect(() => {
    const username = localStorage.getItem('username');
    const userId = localStorage.getItem('userId');
    const userRoleId = localStorage.getItem('userRoleId');
    const token = localStorage.getItem('token');
    const storedDebugMode = localStorage.getItem('debugMode');

    if (token && username && userId && userRoleId) {
      setUsername(username);
      setUserId(userId);
      setUserRoleId(userRoleId);
      setToken(token);
      setDebugMode(storedDebugMode === 'true');
    } else {
      logout();
    }
    
    if (storedDebugMode === null) {
      localStorage.setItem('debugMode', 'false');
    }
    
    setLoading(false);
  }, []);

  // const checkToken = async () => {
  //   try {
  //     await axios.get(`${SERVER_URL}/private/user/me`, {
  //       headers: {
  //         'Accept': 'application/json',
  //         'Authorization': `Bearer ${token}`
  //       }
  //     });
  //     return true;
  //   } catch (error) {
  //     alert('抓取個人資料錯誤, token', token);
  //     return false;
  //   }
  // }

  const value = {
    username,
    userId,
    userRoleId,
    token,
    loading,  // 將 loading 加入 context value
    login,
    logout,
    debugMode,
    setDebugMode: handleDebugModeChange
  };

  return (
    <AuthContext.Provider value={value}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
