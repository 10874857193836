import { SearchRepository } from '@/infrastructure/repositories/SearchRepository';
import { SearchClientsUseCase } from '@/domain/useCases/SearchClientsUseCase';

export class SearchFacade {
  constructor() {
    this.searchRepository = new SearchRepository();
    this.searchClientsUseCase = new SearchClientsUseCase(this.searchRepository);
  }

  /**
   * 搜尋客戶
   * @param {string} chatroomId - 聊天室ID
   * @param {string} searchText - 搜尋文字
   * @returns {Promise<Array<string>>} - 返回符合條件的客戶external_id列表
   */
  async searchClients(chatroomId, searchText) {
    try {
      return await this.searchClientsUseCase.execute(chatroomId, searchText);
    } catch (error) {
      console.error('SearchFacade.searchClients Error:', error);
      throw error;
    }
  }
} 