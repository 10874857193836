import { isStag5, isPanco, isE77, isHealtech, isSC } from './envConfig';

export const routes = (token) => {
  // 建立基本路由配置
  const getRoutes = () => {
    if (isStag5) {
      return [
        { path: '/service', component: '/service/ServicePage' },
        { path: '/service/create', component: '/service/CreateServicePage' },
        { path: '/service/edit/:id', component: '/service/EditServicePage' },
        { path: '/service/order/:id', component: '/service/ServiceOrderPage' },

        { path: '/product', component: '/product/ProductPage' },
        { path: '/product/create', component: '/product/CreateProductPage' },
        { path: '/product/edit/:id', component: '/product/EditProductPage' },
        { path: '/product/order/:id', component: '/product/ProductOrderPage' },

        { path: '/organization', component: '/organization/OrganizationPage' },
        { path: '/organization/create', component: '/organization/CreateOrganizationPage' },
        { path: '/organization/edit/:id', component: '/organization/EditOrganizationPage' },
        { path: '/organization/provider/:id', component: '/organization/ProviderPage' },
        { path: '/organization/service/:id', component: '/organization/OrganizationServicePage' },
        { path: '/organization/product/:id', component: '/organization/OrganizationProductPage' },
        { path: '/organization/service-order/:id', component: '/organization/OrganizationServiceOrderPage' },
        { path: '/organization/product-order/:id', component: '/organization/OrganizationProductOrderPage' },

        { path: '/user', component: '/user/UserPage' },
        { path: '/user/service-order/:id', component: '/user/UserServiceOrderPage' },
        { path: '/user/product-order/:id', component: '/user/UserProductOrderPage' },
      ];
    }

    if (isPanco) {
      return [
        { path: '/service', component: '/service/ServicePage' },
        { path: '/service/create', component: '/service/CreateServicePage' },
        { path: '/service/edit/:id', component: '/service/EditServicePage' },
        { path: '/service/order/:id', component: '/service/ServiceOrderPage' },

        { path: '/product', component: '/product/ProductPage' },
        { path: '/product/create', component: '/product/CreateProductPage' },
        { path: '/product/edit/:id', component: '/product/EditProductPage' },
        { path: '/product/order/:id', component: '/product/ProductOrderPage' },

        { path: '/organization', component: '/organization/OrganizationPage' },
        { path: '/organization/create', component: '/organization/CreateOrganizationPage' },
        { path: '/organization/edit/:id', component: '/organization/EditOrganizationPage' },
        { path: '/organization/provider/:id', component: '/organization/ProviderPage' },
        { path: '/organization/service/:id', component: '/organization/OrganizationServicePage' },
        { path: '/organization/product/:id', component: '/organization/OrganizationProductPage' },
        { path: '/organization/service-order/:id', component: '/organization/OrganizationServiceOrderPage' },
        { path: '/organization/product-order/:id', component: '/organization/OrganizationProductOrderPage' },

        { path: '/user', component: '/user/UserPage' },
        { path: '/user/service-order/:id', component: '/user/UserServiceOrderPage' },
        { path: '/user/product-order/:id', component: '/user/UserProductOrderPage' },
      ];
    }

    if (isE77) {
      return [
        { path: '/campaign', component: '/campaign/CampaignPage' },
        { path: '/campaign/create', component: '/campaign/CreateCampaignPage' },
        { path: '/campaign/edit/:id', component: '/campaign/EditCampaignPage' },

        { path: '/venue', component: '/venue/VenuePage' },
        { path: '/venue/create', component: '/venue/CreateVenuePage' },
        { path: '/venue/edit/:id', component: '/venue/EditVenuePage' },

        { path: '/venue/:id/halls', component: '/hall/HallPage' },
        { path: '/venue/:id/hall/create', component: '/hall/CreateHallPage' },
        { path: '/venue/:id/hall/edit/:id', component: '/hall/EditHallPage' },

        { path: '/ticket', component: '/ticket/TicketPage' },
        { path: '/ticket/create', component: '/ticket/CreateTicketPage' },
        { path: '/ticket/edit/:id', component: '/ticket/EditTicketPage' },

        { path: '/organization', component: '/organization/OrganizationPage' },
        { path: '/organization/create', component: '/organization/CreateOrganizationPage' },
        { path: '/organization/edit/:id', component: '/organization/EditOrganizationPage' },
        { path: '/organization/provider/:id', component: '/organization/ProviderPage' },
        { path: '/organization/service/:id', component: '/organization/OrganizationServicePage' },
        { path: '/organization/product/:id', component: '/organization/OrganizationProductPage' },
        { path: '/organization/service-order/:id', component: '/organization/OrganizationServiceOrderPage' },
        { path: '/organization/product-order/:id', component: '/organization/OrganizationProductOrderPage' },

        { path: '/user', component: '/user/UserPage' },
        { path: '/user/service-order/:id', component: '/user/UserServiceOrderPage' },
        { path: '/user/product-order/:id', component: '/user/UserProductOrderPage' },
      ];
    }

    if (isHealtech) {
      return [
        { path: '/management', component: '/management/ManagementPage' },
        // { path: '/management/create', component: '/management/CreateManagementPage' },
        // { path: '/management/edit/:id', component: '/management/EditManagementPage' },

        { path: '/demand', component: '/demand/DemandPage' },
        { path: '/demand/create', component: '/demand/CreateDemandPage' },
        // { path: '/demand/edit/:id', component: '/demand/EditDemandPage' },
      ];
    }

    if (isSC) {
      return [
        { path: '/management', component: '/management/ManagementPage' },
      ];
    }

    return [];
  };

  // 為每個路由添加權限驗證
  return getRoutes().map(route => ({
    ...route,
    component: token ? route.component : 'Login'
  }));
};
