/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios'

import { useAuth } from '@/contexts/AuthProvider'

import ChatList from './components/list/ChatList'
import ChatInterface from './components/ChatInterface'
import CreateChat from './components/list/CreateChat'

import { LoadingOutlined } from '@ant-design/icons';
import { Spin, message } from 'antd';
import ToolBar from './components/ToolBar';

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

const Chat = () => {
  let { roomId } = useParams();
  const { userId, token } = useAuth();
  const navigate = useNavigate()

  const [chatList, setChatList] = useState([])
  const [activeRoomId, setActiveRoomId] = useState(null)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    fetchRooms()
  }, [userId])

  const fetchRooms = async () => {
    setLoading(true)
    try {
      const userResponse = await axios.get(SERVER_URL + '/private/user/me', {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });

      const idList = userResponse.data.joined_rooms;
      let allChatRooms = [];

      if (userResponse.data.role_id === 4) {
        // 獲取部門列表
        const departmentsResponse = await axios.get(`${SERVER_URL}/private/company/departments/`, {
          headers: {
            'Accept': 'application/json',
            'Authorization': `Bearer ${token}`
          }
        });
        
        // 並行獲取所有部門的聊天室
        const departments = departmentsResponse.data;
        const roomPromises = departments.map(dept => 
          axios.get(`${SERVER_URL}/private/chatrooms/list/department/${dept.id}`, {
            headers: {
              'Accept': 'application/json',
              'Authorization': `Bearer ${token}`
            }
          })
          .then(response => response.data)
          .catch(error => {
            console.error(`Error fetching rooms for department ${dept.id}:`, error);
            return [];
          })
        );

        // 等待所有請求完成
        const roomsResponses = await Promise.all(roomPromises);
        allChatRooms = roomsResponses.flat();
      } else {
        // 使用原本的 API
        const response = await axios.get(SERVER_URL + '/private/chatrooms/list/group/my', {
          headers: { 
            Accept: 'application/json', 
            Authorization: `Bearer ${token}` 
          }
        });
        allChatRooms = response.data.flatMap(group => group.chatrooms);
      }

      // 移除重複的聊天室（如果有的話）
      const uniqueRooms = Array.from(new Map(allChatRooms.map(room => [room.id, room])).values());

      // Filter out the department chatrooms if exist in idList
      let tempChatList = uniqueRooms.filter(chatroom => 
        idList.includes(chatroom.id) || userResponse.data.role_id === 4
      );

      // check if roomid is in tempchatlist
      if (roomId && (tempChatList.filter(x => x.id === roomId).length > 0 || userResponse.data.role_id === 4)) {
        setActiveRoomId(roomId)
        navigate(`/chat/${roomId}`)
      } else if (tempChatList.length > 0) {
        setActiveRoomId(tempChatList[0].id)
        navigate(`/chat/${tempChatList[0].id}`)
      } else {
        setActiveRoomId(null)
        navigate(`/chat`)
      }

      if (tempChatList.length === 0) {
        message.info("尚未加入任何聊天室")
      }

      setChatList(tempChatList)
    } catch (error) {
      message.error(JSON.stringify(error.response?.data?.detail));
    }
    setLoading(false)
  }

  const changeActiveRoomId = (id) => {
    setActiveRoomId(id)
    navigate(`/chat/${id}`)
  }

  const openModal = () => {
    setIsModalOpen(true)
  }

  const closeModal = () => {
    setIsModalOpen(false)
  }

  if (loading) {
    return (
      <div className="flex justify-center items-center w-full h-screen">
        <Spin indicator={<LoadingOutlined style={{ fontSize: 48 }} spin />} />
      </div>
    )
  }

  return (
    <div className="flex flex-row w-full h-full">
      <CreateChat token={token} isModalOpen={isModalOpen} closeModal={closeModal} />
      <ChatList
        userId={userId}
        activeRoomId={activeRoomId}
        chatList={chatList}
        changeActiveRoom={changeActiveRoomId}
        openModal={openModal}
      />
      {
        activeRoomId && chatList.length > 0 ?
          <ChatInterface id={activeRoomId} room={chatList.filter(x => x.id === activeRoomId)[0]} refresh={fetchRooms} />
          :
          <div className="flex flex-col items-center justify-start w-full h-screen">
            <ToolBar pure={true} />
            <div className="text-center my-auto text-2xl font-bold text-textLight">
              請選擇聊天室
            </div>
          </div>
      }
    </div>
  )
}

export default Chat
