import { LineChannelApi } from '../api/LineChannelApi';

export const LineChannelRepository = {
  // ... 其他現有的方法 ...

  /**
   * Add a note to a line channel client
   * @param {string} chatroomId - The chatroom ID
   * @param {string} clientId - The client ID
   * @param {string} noteName - The note name
   * @param {string} noteContent - The note content
   * @returns {Promise<Object>} The response data
   * @throws {Error} If the operation fails
   */
  addNote: async (chatroomId, clientId, noteName, noteContent) => {
    try {
      const response = await LineChannelApi.addNote(chatroomId, clientId, noteName, noteContent);
      return response;
    } catch (error) {
      console.error('Repository Error in addNote:', error);
      // 分類錯誤
      if (error.response?.status === 500) {
        throw new Error('伺服器錯誤');
      } else if (error.response?.status === 404) {
        throw new Error('找不到指定的聊天室或客戶');
      } else if (error.response?.status === 400) {
        throw new Error('無效的請求參數');
      }
      throw error;
    }
  },

  /**
   * Transform the raw client data to frontend format
   * @param {Object} rawData - The raw data from backend
   * @returns {Object} Transformed data for frontend
   */
  _transformClientData: (rawData) => {
    if (!rawData) return null;

    return {
      displayName: rawData.external_profile?.displayName || '',
      userId: rawData.external_id || '',
      language: rawData.external_profile?.language || '',
      pictureUrl: rawData.external_profile?.pictureUrl || '',
      statusMessage: null,
      chatroom_id: rawData.chatroom_id || '',
      last_two_messages: rawData.last_two_messages || [],
      raw_http_response: rawData.external_profile?.status_code === 200 ? 'Succeed' : 'Failed',
      notes: rawData.notes || [],
      tags: rawData.tags || [],
      roomId: rawData.chatroom_id || '',
      settings: rawData.settings || {},
      num_of_unseen_messages: rawData.num_of_unseen_messages || 0,
      external_id: rawData.external_id || '',
      external_channel_id: rawData.external_channel_id || '',
    };
  },

  /**
   * Fetch line clients with transformed data format
   * @param {string} chatroomId - The chatroom ID
   * @returns {Promise<Array>} Transformed client data array
   * @throws {Error} If the operation fails
   */
  fetchLineClients: async (chatroomId) => {
    try {
      const response = await LineChannelApi.fetchLineClients(chatroomId);
      
      // Handle array of clients
      if (Array.isArray(response)) {
        return response.map(client => LineChannelRepository._transformClientData(client));
      }
      
      // Handle single client
      return LineChannelRepository._transformClientData(response);
    } catch (error) {
      console.error('Repository Error in fetchLineClients:', error);
      if (error.response?.status === 500) {
        throw new Error('伺服器錯誤');
      } else if (error.response?.status === 404) {
        throw new Error('找不到指定的聊天室');
      } else if (error.response?.status === 400) {
        throw new Error('無效的請求參數');
      }
      throw error;
    }
  },

  /**
   * Delete a note from a line channel client
   * @param {string} chatroomId - The chatroom ID
   * @param {string} clientId - The client ID
   * @param {string} noteId - The note ID
   * @returns {Promise<Object>} The response data
   * @throws {Error} If the operation fails
   */
  deleteNote: async (chatroomId, clientId, noteId) => {
    try {
      const response = await LineChannelApi.deleteNote(chatroomId, clientId, noteId);
      return response;
    } catch (error) {
      console.error('Repository Error in deleteNote:', error);
      if (error.response?.status === 500) {
        throw new Error('伺服器錯誤');
      } else if (error.response?.status === 404) {
        throw new Error('找不到指定的備註');
      } else if (error.response?.status === 400) {
        throw new Error('無效的請求參數');
      }
      throw error;
    }
  },

  /**
   * Add a tag to a line channel client
   * @param {string} chatroomId - The chatroom ID
   * @param {string} clientId - The client ID
   * @param {string} tagName - The tag name
   * @returns {Promise<Object>} The response data
   * @throws {Error} If the operation fails
   */
  addTag: async (chatroomId, clientId, tagName) => {
    try {
      const response = await LineChannelApi.addTag(chatroomId, clientId, tagName);
      return response;
    } catch (error) {
      console.error('Repository Error in addTag:', error);
      if (error.response?.status === 500) {
        throw new Error('伺服器錯誤');
      } else if (error.response?.status === 404) {
        throw new Error('找不到指定的聊天室或客戶');
      } else if (error.response?.status === 400) {
        throw new Error('無效的請求參數');
      }
      throw error;
    }
  },

  /**
   * Delete a tag from a line channel client
   * @param {string} chatroomId - The chatroom ID
   * @param {string} clientId - The client ID
   * @param {string} tagId - The tag ID
   * @returns {Promise<Object>} The response data
   * @throws {Error} If the operation fails
   */
  deleteTag: async (chatroomId, clientId, tagId) => {
    try {
      const response = await LineChannelApi.deleteTag(chatroomId, clientId, tagId);
      return response;
    } catch (error) {
      console.error('Repository Error in deleteTag:', error);
      if (error.response?.status === 500) {
        throw new Error('伺服器錯誤');
      } else if (error.response?.status === 404) {
        throw new Error('找不到指定的標籤');
      } else if (error.response?.status === 400) {
        throw new Error('無效的請求參數');
      }
      throw error;
    }
  },

  fetchTagGroup: async (chatroomId) => {
    try {
      const response = await LineChannelApi.fetchTagGroup(chatroomId);
      return response;
    } catch (error) {
      console.error('Repository Error in fetchTagGroup:', error);
      throw error;
    }
  }
}; 