/**
 * Line Channel Note Use Cases
 */
export const AddLineChannelNote = {
  /**
   * Add a note to a line channel client
   * @param {Object} repository - The repository instance
   * @param {string} chatroomId - The chatroom ID
   * @param {string} clientId - The client ID
   * @param {string} noteName - The note name
   * @param {string} noteContent - The note content
   * @returns {Promise<Object>} The response data
   * @throws {Error} If the operation fails
   */
  addNote: async (repository, chatroomId, clientId, noteName, noteContent) => {
    try {
      // 驗證輸入參數
      if (!chatroomId || !clientId || !noteName || !noteContent) {
        throw new Error('缺少必要參數');
      }

      // 驗證 noteContent 長度
      if (noteContent.length > 300) {
        throw new Error('備註內容不能超過300字');
      }

      // 調用 repository
      const result = await repository.addNote(chatroomId, clientId, noteName, noteContent);
      return result;
    } catch (error) {
      console.error('UseCase Error in addNote:', error);
      // 處理業務邏輯錯誤
      if (error.message === '伺服器錯誤') {
        throw new Error('系統忙碌中，請稍後再試');
      } else if (error.message === '找不到指定的聊天室或客戶') {
        throw new Error('找不到指定的聊天室或客戶，請確認後重試');
      } else if (error.message === '無效的請求參數') {
        throw new Error('請求參數無效，請確認輸入內容');
      }
      throw error;
    }
  },

  /**
   * Delete a note from a line channel client
   * @param {Object} repository - The repository instance
   * @param {string} chatroomId - The chatroom ID
   * @param {string} clientId - The client ID
   * @param {string} noteId - The note ID
   * @returns {Promise<Object>} The response data
   * @throws {Error} If the operation fails
   */
  deleteNote: async (repository, chatroomId, clientId, noteId) => {
    try {
      // 驗證輸入參數
      if (!chatroomId || !clientId || !noteId) {
        throw new Error('缺少必要參數');
      }

      // 調用 repository
      const result = await repository.deleteNote(chatroomId, clientId, noteId);
      return result;
    } catch (error) {
      console.error('UseCase Error in deleteNote:', error);
      // 處理業務邏輯錯誤
      if (error.message === '伺服器錯誤') {
        throw new Error('系統忙碌中，請稍後再試');
      } else if (error.message === '找不到指定的備註') {
        throw new Error('找不到指定的備註，請確認後重試');
      } else if (error.message === '無效的請求參數') {
        throw new Error('請求參數無效，請確認輸入內容');
      }
      throw error;
    }
  }
}; 